// Uncomment to notify you about avoidable re-renders
// import "../../scripts/wdyr"

import React, { Fragment } from "react"
import { theme } from "@be/biology-editor"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { type AppProps } from "next/app"
import Head from "next/head"

const MyApp: React.FC<AppProps> = (props) => {
  const { Component, pageProps } = props

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side")

    if (jssStyles) {
      jssStyles.remove()
    }
  }, [])

  return (
    <Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{"BioDrive"}</title>
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...pageProps} />
      </ThemeProvider>
    </Fragment>
  )
}

// ts-unused-exports:disable-next-line
export default MyApp
